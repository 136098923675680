<template>
  <div>
    <el-dialog title="回复"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="50%"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               ref="formRef"
               label-width="100rem">

        <el-row :gutter="10">
          <el-col :span="23">
            <el-form-item label="内容:"
                          prop="discuss_content">
              <el-input placeholder="请输入回复内容"
                        style="width:100%"
                        v-model="form.discuss_content"
                        type="textarea"
                        rows="6" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="23">
            <el-form-item label="图片:">
              <div class="images">
                <div v-for="(item,index) in images"
                     :key="item">
                  <span>
                    <img src="@/assets/del.png"
                         @click="delImage(index)"
                         alt="">
                  </span>
                  <img :src="item"
                       style=" width: 120rem;height: 120rem;margin-right:10rem"
                       alt="">
                </div>
                <div class="add_img"
                     v-if="images.length  != 2"
                     @click="uploadImage">
                  <img src="@/assets/add_imgi.png"
                       alt="">
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>

      <div class="btn_warp">
        <div @click="handleClose">退出</div>
        <div @click="save">发表</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { uploadImageAuto } from '@/api/upload.js'

export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      images: [],
      rules: {
        discuss_title: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        discuss_content: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
      }
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
      this.images = []
      this.$refs.formRef.resetFields()
    },
    save () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        form.discuss_image = this.images.join(',')
        this.$http({
          url: '/api/v1/discuss/re_post_done',
          method: 'post',
          data: form
        }).then(res => {
          this.$message.success('发帖成功')
          this.handleClose()
          this.$parent.getTieziInfo()
        })
      })

    },
    async uploadImage () {
      const { data } = await uploadImageAuto()
      this.images.push(data.info.dir)
    },
    addImage (str) {
      if (!str) {
        this.$message.warning('失败')
        return
      }
      this.images.push(this.$addHeader(str))
    },
    delImage (index) {
      this.images.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.text_warp {
  word-break: break-all;
  font-weight: 500;
  color: #666666 !important;
  line-height: 23rem;
  font-size: 16rem;
  padding: 27rem;
  padding-top: 0;
}
.btn_warp {
  border-top: 1rem solid #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70rem;
  div {
    flex: 1;
    height: 70rem;
    font-weight: bold;
    font-size: 24rem;
    display: flex;
    justify-content: center;
    align-items: center;
    &:nth-child(2) {
      color: #2196f3;
      border-left: 1rem solid #f4f4f4;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__header {
  font-size: 24rem;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
::v-deep .el-dialog {
  border-radius: 10rem;
}

.add_img {
  width: 120rem;
  height: 120rem;
  font-size: 14rem;
  font-weight: 500;
  background: #f6f6f6;
  border-radius: 5rem;
  text-align: center;
  color: #2196f3;
  border-radius: 5rem;
  img {
    width: 120rem;
    height: 120rem;
  }
}
.images {
  display: flex;
  div {
    position: relative;
    span {
      position: absolute;
      right: -10rem;
      top: -10rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 30rem;
        height: 30rem;
      }
    }
    margin-right: 20rem;
  }
}

::v-deep .el-form-item__label {
  font-size: 24rem;
}
::v-deep .el-textarea__inner {
  font-size: 24rem;
}
</style>