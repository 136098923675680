<template>
  <div class="info_warp">
    <TopCard ref="TopCardRef"
             text="线上答疑"
             :showBack="true" />
    <div class="huifu_btn"
         @click="huifu">
      回复
    </div>
    <div class="wrap">
      <div class="tiwen">
        <div class="head">
          <div>
            <img src="@/assets/dayi/toux.jpg"
                 alt="" />
            {{ info.discuss_parent.discuss_title || '' }}
          </div>
          <div>
            {{ info.discuss_parent.discuss_time }}
          </div>
        </div>
        <div class="content">
          <div class="left">
            {{ info.discuss_parent.discuss_content }}
            <div class="images">
              <el-image style="width: 120rem; height: 120rem;border-radius:10rem;"
                        v-for="(item, index) in info.discuss_parent.discuss_image"
                        :key="index"
                        :src="item"
                        :preview-src-list="info.discuss_parent.discuss_image">
              </el-image>
            </div>
          </div>
          <div class="btn">
            <div class="right">
              <img src="@/assets/dayi/icon-未点赞@2x.png"
                   @click="dianzan"
                   v-if="show"
                   alt="" />
              <img src="@/assets/dayi/icon-已点赞@2x.png"
                   v-else
                   alt="" />
              <span>
                {{ info.discuss_parent.discuss_upvote_times }}
              </span>
            </div>
          </div>
        </div>

        <!-- associated question -->
        <div class="associated_question"
             v-if="info.discuss_parent.question_data">

          <div class="header">
            <div class="button"
                 @click="isShowAssociatedQuestion = !isShowAssociatedQuestion">
              <div class="button-item"
                   v-show="isShowAssociatedQuestion">
                <div>查看题目</div>
                <img style="width: 20rem; height: 13rem"
                     src="@/assets/dayi/pull-down.png"
                     alt="" />
              </div>
              <div class="button-item"
                   v-show="!isShowAssociatedQuestion">
                <div>隐藏题目</div>
                <img style="width: 20rem; height: 13rem"
                     src="@/assets/dayi/pull-up.png"
                     alt="" />
              </div>
            </div>
          </div>
          <div class="body"
               v-show="!isShowAssociatedQuestion">
            <div class="title">题目内容(ID:{{info.discuss_parent.question_id}})</div>
            <div class="content border-radius contentBlock">
              <div v-html="info.discuss_parent.question_data.question_head_content"></div>
            </div>
            <div class="content contentBlock"
                 v-html="info.discuss_parent.question_data.question_content"></div>
            <div class="title">题目答案</div>
            <div class="content border-radius contentBlock"
                 v-html="info.discuss_parent.question_data.question_answer"></div>
            <div class="title">题目解析</div>
            <div class="content contentBlock"
                 v-html="info.discuss_parent.question_data.question_explain">
            </div>
          </div>

        </div>
      </div>

      <div class="tiwen"
           style="margin-top:20rem"
           v-if="info.discuss_parent.review_status === -1">
        <div class="head">
          <div>
            <img src="@/assets/dayi/toux.jpg"
                 alt="" />
            {{ info.discuss_parent.discuss_title || '' }}
          </div>
          <div>
            {{ info.discuss_parent.review_time }}
          </div>
        </div>

        <div class="reject_warp">
          <div class="reject_content">
            未通过原因:{{info.discuss_parent.reason}}
          </div>
          <div class="reject_btn">
            审核不通过
          </div>
        </div>
      </div>

      <div class="common"
           v-if="info.volist_children.length">
        <div class="huida"
             v-for="(item, index) in info.volist_children"
             :key="index">
          <div class="head">
            <div>
              <img src="@/assets/dayi/toux.jpg"
                   alt="" />
              {{ item.user_id }}
            </div>
            <div>
              {{ item.discuss_time }}
            </div>
          </div>
          <div class="content">
            <div class="left">
              <div v-html="item.discuss_content">
              </div>
              <div class="btn">
                <div class="images">
                  <el-image style="width: 80rem; height: 80rem"
                            v-for="(item, index) in item.discuss_image"
                            :key="index"
                            :src="item"
                            :preview-src-list="item.discuss_image">
                  </el-image>
                </div>
              </div>
            </div>
            <div class="right">
              <img src="@/assets/dayi/icon-未点赞@2x.png"
                   @click="dianzanChild(item, index)"
                   v-if="item.show"
                   alt="" />
              <img src="@/assets/dayi/icon-已点赞@2x.png"
                   v-else
                   alt="" />
              <span>
                {{ item.discuss_upvote_times }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- v-if="info.discuss_parent.review_status !== -1" -->
    <!-- <div class="fixed_btn"
         @click="huifu">
      回 复
    </div> -->
    <HuifuForm ref="HuifuFormRef" />
  </div>
</template>

<script>
import HuifuForm from './huifu.vue';
import TopCard from '@/views/components/top_card/index.vue'

export default {
  data () {
    return {
      info: {
        discuss_parent: {
          discuss_title: '',

        },
        question_data: {
          question_content: ''
        },
        volist_children: []
      },
      show: true,
      isShowAssociatedQuestion: true
    };
  },
  components: {
    HuifuForm, TopCard
  },
  mounted () {
    this.getTieziInfo();
  },
  methods: {
    getTieziInfo () {
      this.$http({
        url: '/api/v1/discuss/show_post',
        method: 'get',
        params: {
          discuss_id: this.$route.query.discuss_id,
          is_pc: 1
        }
      }).then(res => {
        res.data.discuss_parent.discuss_image = res.data.discuss_parent.discuss_image
          ? JSON.parse(res.data.discuss_parent.discuss_image)
          : [];
        res.data.volist_children = res.data.volist_children.map(item => {
          item.show = true;
          return item;
        });

        res.data.volist_children.map(item => {
          item.discuss_image = item.discuss_image ? JSON.parse(item.discuss_image) : [];
          return;
        });
        this.info = res.data;
      });
    },
    dianzan () {
      this.$http({
        url: '/api/v1/discuss/add_upvote',
        method: 'post',
        data: {
          discuss_id: this.info.discuss_parent.discuss_id
        }
      }).then(() => {
        this.$message.success('点赞成功');
        this.info.discuss_parent.discuss_upvote_times += 1;
        this.show = false;
        this.$forceUpdate();
      });
    },
    dianzanChild (item, index) {
      this.$http({
        url: '/api/v1/discuss/add_upvote',
        method: 'post',
        data: {
          discuss_id: item.discuss_id
        }
      }).then(() => {
        this.$message.success('点赞成功');
        this.info.volist_children[index].discuss_upvote_times += 1;
        this.info.volist_children[index].show = false;
      });
    },
    huifu () {
      let form = {
        discuss_parent_id: this.$route.query.discuss_id,
        discuss_image: [],
        discuss_content: ''
      };
      this.$refs.HuifuFormRef.images = [];
      this.$refs.HuifuFormRef.form = form;
      this.$refs.HuifuFormRef.dialogVisible = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.info_warp {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background: #f6f6f6;
  .tiwen {
    box-shadow: 0rem 1rem 5rem 1rem rgba(226, 223, 223, 0.4);
    background: white;
    padding-bottom: 10rem;
    .head {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 40rem);
      margin: 0 auto;
      border-bottom: 1rem solid #e9e9e9;
      height: 79rem;
      align-items: center;
      div {
        &:nth-child(1) {
          font-size: 24rem;
          font-weight: bold;
          color: #333333;
          display: flex;
          align-items: center;
          text-indent: 5rem;
          img {
            width: 40rem;
            height: 40rem;
            margin-right: 10rem;
          }
        }
        &:nth-child(2) {
          font-size: 20rem;
          font-weight: 500;
          color: #999999;
        }
      }
    }
    .content {
      width: calc(100% - 40rem);
      margin: 0 auto;
      margin-top: 17rem;
      font-size: 24rem;
      font-weight: 500;
      color: #333333;
      display: flex;
    }
    .contentBlock {
      display: block;
    }
    .associated_question {
      padding: 17rem 20rem 0 20rem;
      .header {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .button {
          padding: 6rem;
          display: inline-block;
          font-weight: bold;
          font-size: 24rem;
          color: #3f9eff;
          .button-item {
            display: flex;
            align-items: center;
            div {
              margin-right: 5rem;
            }
          }
        }
      }
      .body {
        font-size: 24rem !important;
        .title {
          position: relative;
          margin: 10rem 7rem;
          font-weight: bold;
          font-size: 24rem;
          height: 30rem;
          text-indent: 10rem;
        }
        // 左边框
        .title::before {
          content: "";
          position: absolute;
          display: inline-block;
          top: 5rem;
          left: -7rem;
          width: 6rem;
          height: 25rem;
          background-color: #2196f3;
        }
        .content {
          margin: 10rem 7rem;
          padding: 7rem;
          line-height: 50rem;
        }
        .border-radius {
          border-bottom: 1rem solid #e9e9e9;
        }
      }
    }
  }

  .common {
    box-shadow: 0rem 1rem 5rem 1rem rgba(226, 223, 223, 0.4);
    margin-top: 20rem;
    .huida {
      background: white;
      margin-top: 20rem;
      .head {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 40rem);
        margin: 0 auto;
        border-bottom: 1rem solid #e9e9e9;
        height: 79rem;
        align-items: center;
        div {
          &:nth-child(1) {
            font-size: 24rem;
            font-weight: bold;
            color: #333333;
            display: flex;
            align-items: center;
            text-indent: 5rem;
            img {
              width: 40rem;
              height: 40rem;
            }
          }
          &:nth-child(2) {
            font-size: 20rem;
            font-weight: 500;
            color: #999999;
          }
        }
      }
      .content {
        width: calc(100% - 40rem);
        margin: 0 auto;
        margin-top: 17rem;
        font-size: 24rem;
        font-weight: 500;
        color: #333333;
        padding-bottom: 10rem;
        display: flex;
        .btn {
          position: relative;
          display: flex;
          color: #666666;
          font-weight: 500;
          font-size: 16rem;
          display: flex;
          justify-content: space-between;
          .images {
            position: relative;
            img {
              width: 50rem;
              height: 50rem;
              margin-right: 5rem;
            }
          }
          .right {
            position: relative;
            margin-right: 3rem;
            display: flex;
            align-items: flex-start;
            font-size: 16rem;
            img {
              width: 24rem;
              margin-right: 3rem;
            }
          }
        }
      }
    }
  }
  .fixed_btn {
    width: calc(100% - 40rem);
    margin-left: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44rem;
    background: #2196f3;
    border-radius: 5rem;
    position: fixed;
    bottom: 20rem;
    font-weight: bold;
    color: #ffffff;
  }
}
.reject_warp {
  display: flex;
  height: 108rem;
  .reject_content {
    flex: 1;

    font-weight: bold;
    font-size: 16rem;
    text-indent: 20rem;
    margin-top: 15rem;
  }
  .reject_btn {
    width: 115rem;
    margin-right: 20rem;
    margin-top: 15rem;
    border-radius: 18rem;
    height: 35rem;
    background: #fedddd;
    font-weight: bold;
    color: #ff664e;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.right {
  width: 100rem;
  position: relative;
  margin-right: 3rem;
  display: flex;
  align-items: flex-start;
  font-size: 24rem;
  img {
    width: 32rem;
    height: 32rem;
    margin-right: 3rem;
  }
}
.left {
  flex: 1;
  font-size: 24rem;
}
.wrap {
  height: calc(100vh - 100rem);
  overflow: auto;
}
.huifu_btn {
  width: 140rem;
  height: 54rem;
  background: #2196f3;
  border-radius: 35rem;
  font-size: 24rem;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 40rem;
  top: 23rem;
}

.btn {
  width: 100rem;
  position: relative;
  display: flex;
  color: #666666;
  font-weight: 500;
  display: flex;
  background: #fff;
  justify-content: space-between;
  .images {
    position: relative;
    img {
      width: 50rem;
      height: 50rem;
      margin-right: 5rem;
    }
  }
}
</style>
